// import qs from 'qs';
// import axios from 'axios';
// import React, { useEffect, useState } from 'react';
// import { apiUrl, isDevelopment, tokenKey } from './global';
// import { useQuery, useMutation, useQueryClient } from 'react-query';
// import { LocalKey, LocalStorage } from "ts-localstorage";
// import { useNotify } from './components/useNotify';

// import AMapLoader from "@amap/amap-jsapi-loader";
// import { mapKey, mapSecretKey } from "./global";

// function sleep(time) {
//   return new Promise((resolve) => setTimeout(resolve, time));
// }

// let _AMap = null;
function App() {

  // useEffect(() => {
  //   window._AMapSecurityConfig = { securityJsCode: mapSecretKey };

  //   AMapLoader.load({
  //     key: mapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
  //     // version: "2.1Beta", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
  //     // 需要使用的的插件列表，如比例尺'AMap.Scale'等
  //     plugins: ["AMap.Geocoder", "Map3D"],
  //   })
  //     .then((AMap) => {
  //       if (_AMap != null) return;
  //       _AMap = AMap;
  //       var geocoder = new AMap.Geocoder({
  //         // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
  //         city: "乐山",
  //       });

  //       const aFunc = (_id, _address) => {
  //         return new Promise((resolve, reject) => {
  //           geocoder.getLocation("井研县" + _address, function (status, result) {
  //             if (status === "complete" && result.info === "OK") {
  //               let { lng, lat } = result.geocodes[0].location;
  //               const a1 = { _id, _address, lng, lat };
  //               console.log(a1);
  //               resolve(a1);
  //             }
  //             else
  //               console.log(status, result);
  //           });
  //         });
  //       }

  //       //
  //       var dizhis = [
  //         [
  //           "1",
  //           "井研县研经镇断桥村"
  //         ],
  //         [
  //           "2",
  //           "井研县研经镇王家沟村"
  //         ],
  //         [
  //           "3",
  //           "井研县研经镇横连村"
  //         ],
  //         [
  //           "4",
  //           "井研县研经镇大团村"
  //         ],
  //         [
  //           "5",
  //           "井研县研经镇四方村"
  //         ],
  //         [
  //           "6",
  //           "井研县研经镇石堰村"
  //         ],
  //         [
  //           "7",
  //           "井研县研经镇同前村"
  //         ],
  //         [
  //           "8",
  //           "井研县宝五镇五龙场村"
  //         ],
  //         [
  //           "9",
  //           "井研县宝五镇曙光村"
  //         ],
  //         [
  //           "10",
  //           "井研县宝五镇瓦窑村"
  //         ],
  //         [
  //           "11",
  //           "井研县门坎镇门坎村"
  //         ],
  //         [
  //           "12",
  //           "井研县门坎镇大水湾村"
  //         ],
  //         [
  //           "13",
  //           "井研县门坎镇白合村"
  //         ],
  //         [
  //           "14",
  //           "井研县集益镇雨台村"
  //         ],
  //         [
  //           "15",
  //           "井研县集益镇界牌村"
  //         ],
  //         [
  //           "16",
  //           "井研县集益镇幸福村"
  //         ],
  //         [
  //           "17",
  //           "井研县集益镇繁荣村"
  //         ],
  //         [
  //           "18",
  //           "井研县集益镇黄马村"
  //         ],
  //         [
  //           "19",
  //           "井研县研城街道五谷村"
  //         ],
  //         [
  //           "22",
  //           "井研县研城街道新兴"
  //         ],
  //         [
  //           "25",
  //           "井研县研城街道新道路村"
  //         ],
  //         [
  //           "27",
  //           "井研县研城街道飞跃村"
  //         ],
  //         [
  //           "29",
  //           "井研县三江镇三江村"
  //         ],
  //         [
  //           "30",
  //           "井研县三江镇新胜村"
  //         ],
  //         [
  //           "31",
  //           "井研县三江镇解放村"
  //         ],
  //         [
  //           "32",
  //           "井研县王村镇五农村"
  //         ],
  //         [
  //           "33",
  //           "井研县高凤镇双堰村"
  //         ],
  //         [
  //           "34",
  //           "井研县高凤镇红星村"
  //         ],
  //         [
  //           "35",
  //           "井研县高凤镇高凤村"
  //         ],
  //         [
  //           "36",
  //           "井研县高凤镇同力村"
  //         ],
  //         [
  //           "38",
  //           "井研县竹园镇胜泉村"
  //         ],
  //         [
  //           "40",
  //           "井研县竹园镇大胜村"
  //         ],
  //         [
  //           "41",
  //           "井研县竹园镇高石坎村"
  //         ],
  //         [
  //           "43",
  //           "井研县竹园镇烈士村"
  //         ],
  //         [
  //           "44",
  //           "井研县竹园镇石牛村"
  //         ],
  //         [
  //           "46",
  //           "井研县纯复镇红庙村"
  //         ],
  //         [
  //           "47",
  //           "井研县纯复镇田家沟"
  //         ],
  //         [
  //           "48",
  //           "井研县纯复镇观塘村"
  //         ],
  //         [
  //           "49",
  //           "井研县纯复镇观塘村（尖钵村）"
  //         ],
  //         [
  //           "50",
  //           "井研县纯复镇跃进"
  //         ],
  //         [
  //           "51",
  //           "井研县纯复镇青龙"
  //         ],
  //         [
  //           "52",
  //           "井研县周坡镇周坡村"
  //         ],
  //         [
  //           "53",
  //           "井研县周坡镇石马村"
  //         ],
  //         [
  //           "54",
  //           "井研县周坡镇团山村"
  //         ],
  //         [
  //           "55",
  //           "井研县周坡镇龙桥村"
  //         ],
  //         [
  //           "56",
  //           "井研县周坡镇金紫村"
  //         ],
  //         [
  //           "57",
  //           "井研县周坡镇乌抛村"
  //         ],
  //         [
  //           "58",
  //           "井研县周坡镇友盟村"
  //         ],
  //         [
  //           "59",
  //           "井研县周坡镇狮子村"
  //         ],
  //         [
  //           "60",
  //           "井研县周坡镇大河村"
  //         ],
  //         [
  //           "61",
  //           "井研县周坡镇大佛村"
  //         ],
  //         [
  //           "62",
  //           "井研县周坡镇黄桷村"
  //         ],
  //         [
  //           "63",
  //           "井研县周坡镇玉皇顶村"
  //         ],
  //         [
  //           "64",
  //           "井研县镇阳镇两河村"
  //         ],
  //         [
  //           "65",
  //           "井研县镇阳镇石牛坝村"
  //         ],
  //         [
  //           "66",
  //           "井研县镇阳镇龙申村"
  //         ],
  //         [
  //           "67",
  //           "井研县镇阳镇毛坝场村"
  //         ],
  //         [
  //           "68",
  //           "井研县镇阳镇云峰村"
  //         ],
  //         [
  //           "69",
  //           "井研县马踏镇南河村"
  //         ],
  //         [
  //           "72",
  //           "井研县马踏镇四合村"
  //         ],
  //         [
  //           "75",
  //           "井研县马踏镇清河村"
  //         ],
  //         [
  //           "76",
  //           "井研县东林镇打鼓滩"
  //         ],
  //         [
  //           "77",
  //           "井研县东林镇红花村"
  //         ],
  //         [
  //           "78",
  //           "井研县东林镇高佳村"
  //         ],
  //         [
  //           "79",
  //           "井研县集益镇界牌村"
  //         ],
  //         [
  //           "80",
  //           "井研县千佛镇瓦子坝村"
  //         ],
  //         [
  //           "81",
  //           "井研县千佛镇民建村"
  //         ],
  //         [
  //           "82",
  //           "井研县千佛镇新群村"
  //         ],
  //         [
  //           "83",
  //           "井研县千佛镇千佛村"
  //         ],
  //         [
  //           "84",
  //           "井研县千佛镇千佛街社区"
  //         ]
  //       ];
  //       var aArr = [];
  //       console.log("length" + dizhis.length);
  //       (async () => {
  //         for (var ci in dizhis) {
  //           var _id = dizhis[ci][0];
  //           var _address = dizhis[ci][1];
  //           aArr.push(await aFunc(_id, _address));
  //           await sleep(500);
  //         }
  //         console.log(aArr);
  //         console.log(JSON.stringify(aArr));
  //       })();
  //       //

  //     })

  // }, [])



  return <div>App Index</div>
}

export default App;
